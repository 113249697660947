#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 15px 0 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
    @media only screen and (max-width: 1300px){
      max-width: 1000px;
    }
  }

  #home & {
    background: transparent;
    #siteContainer.container-fluid { background: transparent; }
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 15px;
    font-size: 2.25em;
    font-weight: 600;
    border-bottom: $borderWidth solid $borderColor;
    text-transform: initial;
    margin-bottom: 15px;
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}

.user_mode .construction-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: white url(../images/construction.jpg) no-repeat center center fixed;
	-webkit-background-size: 75%;
	-moz-background-size: 75%;
	-o-background-size: 75%;
	background-size: 75%;
	z-index: 9999999999999;
	top: 0;
	left: 0;
}

// Footer partners //
#partner-container {
  background: white;
  max-width: 1120px;
  margin: auto;
  clear: both;

  &:after {
    content: '';
    clear: both;
  }

  h3 {
    text-align: center;
    border-bottom: solid 1px $light;
    margin-bottom: 15px;
    padding: 15px;
  }

  // &:after {
  //   clear: both;
  //   content: '';
  // }

  a {
    display: inline-block;
    vertical-align: middle;
    transition: opacity $transition-standard;
    @media only screen and (min-width: 768px){ padding: 1% 2% 2% 2%; }
    @media only screen and (max-width: 768px){
      padding: 1% 2% 2% 2%;
      width: 20% !important;
    }

    &:hover { opacity: .75; }

    img {
      max-width: 125px;
      width: 100%;
    }

  }


}

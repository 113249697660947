// Share Button Styles //
@mixin bgImg($file){
	background-image: url('../images/#{$file}');
}

.share-this {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #bbbbbb;
  font-size: 14px;
  font-weight: 500 !important;
  display: inline-block;
  float: none !important;
  overflow: visible !important;
	padding: 5px 10px;
	background: black;
	color: white;
	width: auto !important;
	transition: all $transition-standard;

  &:hover {
    cursor: pointer;
    background: $accent-color;
    text-decoration: none;
		color: white;
  }

  &:after {
    content: '\f1e0';
    margin-left: 5px;
    font-family: FontAwesome;
  }

  .social-share {
    position: absolute;
    background: white;
    bottom: calc(100% - 10px);
    padding: 10px;
    border: solid 1px $light;
    border-radius: 2px;
    opacity: 0;
    transition: all $transition-standard;
    z-index: -10;
		min-width: 154px;

    &.open {
      bottom: calc(100% + 10px);
      opacity: 1;
      z-index: 10;
    }

    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $light;
      top: calc(100% + 1px);
      left: 15px;
    }

    ul {
      padding: 0 !important;

      li {
        list-style-type: none;
        height: 40px;
        width: 40px;
        float: left;
        margin: 0 2px;

        a {
          height: 40px;
          width: 40px;
          display: block;
        }

        &.socialFacebook {
          @include bgImg('socialFacebook.svg');
          background-color: #3b5998;
          background-position: center;
          background-size: auto 50%;
          background-repeat: no-repeat;
        }

        &.socialTwitter {
          @include bgImg('socialTwitter.svg');
          background-color: #019FE9;
          background-position: center;
          background-size: auto 50%;
          background-repeat: no-repeat;
        }

        &.socialEmail {
          @include bgImg('socialEmail.svg');
          background-color: #76868D;
          background-position: center;
          background-size: auto 50%;
          background-repeat: no-repeat;
        }

      }
    }

  }

}

.item.extendedOptions {

  .share-this { display: none; }

}

/* News Aggregator */
// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More

// 1. Mixins (Remove when liquid)
@mixin newsagg-header{
  font-size: em(24);
  font-weight: 700;
  line-height: 1.35;
  a { transition: color $transition-standard; }
}
@mixin newsagg-image{
  display: inline-block;
  float: left;
  padding: 3px 15px 7px 0px;
  img{
    width: auto;
    display: block;
  }
}
@mixin newsagg-date-author{
  color: #666;
  font-size: em(12);
  line-height: 1.25;
  display: inline-block;
  margin-right: .5em;
}
@mixin newsagg-teaser{
  font-family: $fontTwo;
  font-size: em(14);
  font-weight: 400;
  line-height: 1.25;
  color: $bodyCopyColorTwo;
  margin: 5px 0 .75em;
}

// 2. Global styles
.newsAggregatorElement{
  .item{
    @include list-item();
    position: relative;

    h4 a {

      &:hover {
        text-decoration: none;
        color: $accent-color;
        text-decoration: none;
      }
    }

  }
  #home & {
    .item {
      padding: 10px 0;
      margin: 0 15px;
    }
  }
  .dateAuthor{
    line-height: 1;
    margin-bottom: 5px;
  }
  .newsAuthor, .newsDate {
    @include newsagg-date-author;
    color: #ababab;
  }
  .newsDate { margin-bottom: 10px; }
  .newsAuthor { display: none; }
  .articleHasImage{
    > h4, .newsText, .commentsAndMore, .newsItemHeader{
      padding-left: 90px;
    }
    .newsItemHeader{
      position: relative;
      > a{
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
  }
}

//
.aggHeader{
  @include special-header;
}

// 3. Expanded Previews
.expanded{
  .newsItemHeader > a{
    @include newsagg-image;
  }
  .newsItemElement{
    h4{ @include newsagg-teaser; }
  }
  .newsItemHeader{
    h2 { @include newsagg-header; }
  }
  .newsText{
    clear: both;
    margin-top: 1em;
    h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
      font-size: 1em;
      letter-spacing: normal;
      margin: 0 0 .5em 0;
      padding: 0;
    }
    ul{
      list-style-type: disc;
    }
  }
}

// 4. Condensed Previews
.newsAggregatorElement .condensed{
  li {line-height: 1;}
  > a{
    display: inline-block;
    float: right;
    width: 40%;
    max-height: 150px;
    overflow: hidden;
    @media only screen and (max-width: 480px){
      width: 100%;
      margin-bottom: 5px;
      max-height: 200px;
    }

    img { width: 100%; }

    .newsAggregatorElement &{ @include newsagg-image; }
  }
  .teaser{ @include newsagg-teaser; }
  .details, > ul {
    list-style-type: none;
    h4{ @include newsagg-header; }
  }
  .details{
    width: 55%;
    padding-bottom: 25px;
    @media only screen and (max-width: 480px){
      width: 100%;
    }
  }

}

// 5. Read more and comments
.commentCount {
  .button-commentCount {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;
    &:hover{background: none;}
    .button-image-right {
      display: none;
    }
    .button-content {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      background: none;
      color: $link-color;
      font-weight: 600;
      top: 2px;
      transition: all $transition-standard;
      &:hover {
        background: none;
        border: none;
        color: $hover-color;
      }
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        font-family: FontAwesome;
        line-height:1;
        content: "\f0e5";
        top: -1px;
        right: 0px;
        padding-right: .25em;
      }
    }
  }
}
.commentsAndMore{
  line-height: 1;
  margin: 5px 0 0 0;
  .readMore{
    display: none;
    margin-right: .5em;
    a{margin: 0;}
  }
}

// Custom CTA
.user_mode .custom-agg {
  width: calc(50% - 8px);
  float: left;
  @media only screen and (max-width: 480px){
    width: 100%;

    &:nth-of-type(even) {
      margin-right: 0 !important;
    }

    &:nth-of-type(odd) {
      margin-left: 0 !important;
    }

  }

  .share-this { display: none !important; }

  .newsAggregatorElement {
    .item {
      padding: 0 !important;
      margin: 0 !important;

      .details { width: 100%; }

      &.extendedOptions {
        margin: 0 15px !important;
        color: white;

        > a {
          margin-top: 10px;
          color: $gray;

          &.rssLink { margin-top: -15px; }

        }

        a[title*="View All"] { display: none; }

      }

      ul {
        padding-left: 0;
        padding: 15px;
      }

      > a {
        padding: 0;
        width: 100%;
        max-height: 250px;
        overflow: hidden;
        margin-bottom: 10px;
        position: relative;

        img { width: 100%; }

      }

      > ul h4 {
        font-size: 22px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 60px;

        a { transition: color $transition-standard; }

      }

      .teaser {
        width: 100%;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        min-height: 40px;
        max-height: 40px;
        .has-mobile-nav & {
          white-space: normal;
          text-overflow: initial;
          overflow: visible;
        }
      }

      .dateAuthor,
      .commentsAndMore { display: none; }

    }
  }

  &:nth-of-type(even) {
    margin-right: 8px;
  }

  &:nth-of-type(odd) {
    margin-left: 8px;
  }

  &:hover {
    h4 a { color: $accent-color; }

    .flag { padding: 5px 15px 5px 25px; }

  }

  .flag {
    position: absolute;
    top: 30px;
    font-size: 14px;
    padding: 5px 15px;
  }

}

.flag {
  background: $accent-color;
  position: relative;;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 7px 25px;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: .5px;
  font-size: 12px;
  transition: padding $transition-standard;
}

// 7. Responsive
@include page-element-break(400){
  .newsAggregatorElement{
    .articleHasImage{
      > h4, .newsText, .commentsAndMore, .newsItemHeader{
        padding-left: 0;
      }
      .newsItemHeader{
        > a{
          position: relative;
          top: initial;
          left: initial;
        }
      }
    }
    .item > a {
      position: relative;
      top: initial;
      left: initial;
    }
    .condensed .details{
      padding-left: 0;
    }
  }
}

@include page-element-break(300){
  .newsAggregatorElement{
    .articleHasImage{
      > h4, .newsText, .commentsAndMore, .newsItemHeader{
        padding-left: 0;
      }
      .newsItemHeader{
        > a{
          position: relative;
          float: none;
        }
      }
    }
    .item > a {
      position: relative;
      float: none;
    }
    .condensed .details{
      padding-left: 0;
    }
  }
}


@mixin customAggSmall(){
  // 33-column styles
  .newsAggregatorElement {
    .item { padding: 15px 0; }
    .newsDate { margin: 5px 0 0; }
    .condensed {
      > a {
        width: 100%;
        max-height: 200px;
      }
      .details {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
}

.layout-50-25-25 {
  .column-2,
  .column-3 { @include customAggSmall(); }
}

.layout-25-50-25 {
  .column-1,
  .column-3 { @include customAggSmall(); }
}

.layout-25-25-50 {
  .column-1,
  .column-2 { @include customAggSmall(); }
}

.layout-33-66 .column-1 { @include customAggSmall(); }
.layout-66-33 .column-2 { @include customAggSmall(); }

.layout-33-33-33 {
  .column-1,
  .column-2,
  .column-3 { @include customAggSmall(); }
}



// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px){
  .layout-25-25-50,
  .layout-50-25-25{
    .col-md-3, .col-md-6{
      .newsAggregatorElement{
        .articleHasImage{
          > h4, .newsText, .commentsAndMore, .newsItemHeader{
            padding-left: 90px;
          }
          .newsItemHeader{
            > a{
              position: absolute;
              top: -2px;
              left: 0;
            }
          }
        }
        .condensed > a {
          display: inline-block;
          float: left;
          padding: 7px 15px 7px 0px;
        }
        .condensed .details{
          padding-left: 90px;
        }
      }
    }
  }
  .layout-25-50-25{
    .col-md-3, .col-md-6{
      .newsAggregatorElement{
        .articleHasImage{
          > h4, .newsText, .commentsAndMore, .newsItemHeader{
            padding-left: 0;
          }
          .newsItemHeader{
            > a{
              position: relative;
              display: block;
              float: none;
            }
          }
        }
        .condensed > a {
          display: block;
          float: none;
        }
        .condensed .details{
          padding-left: 0;
        }
      }
    }
  }
}

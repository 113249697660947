// Trim News Aggregator Styles (Home Page) //
.trim-news {
  // Hide Contents\
  .extendedOptions { color: white; }

  .newsAggregatorElement .item > a { display: none; }

  .share-this { display: none !important; }

  .item {
    li,
    .newsText,
    .newsItemElement h4,
    .dateAuthor,
    .newsItemHeader > a {
      display: none;

      h4 {
        padding: 0 10px;
        font-size: 16px !important;
        a {
          font-weight: 300;
          color: $gray;
          transition: all $transition-standard;
          display: block;
        }
      }
    }

    .details {
      width: 100%;
      padding-bottom: 0;
    }

    .newsItemHeader h2 {
      padding: 0 10px;

      a {
        font-weight: 300;
        color: $gray;
        transition: all $transition-standard;
        display: block;
      }
    }

    &:hover {

      li h4 a,
      .newsItemHeader h2 a {
        color: $accent-color;
        text-decoration: none;
      }

    }
  }
  // Show first List item
  .item li:first-of-type { display: block; }
  // SHow first item
  .newsAggregatorElement .item:first-of-type {

    h4 {
      padding: 0;
      a {
        font-weight: 700;
        font-size: 28px;
        color: $link-color;
        display: block;
      }
    }

    .newsItemHeader h2 {
      padding: 0;
      a {
        font-weight: 700;
        font-size: 28px;
        color: $link-color;
        display: block;
      }
    }

    li,
    > a,
    .newsItemHeader > a { display: inline-block; }

    .newsItemHeader {
      > a {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;

        img {
          width: 100%;
          padding: 0;
        }

      }

    }

    > a {
      width: 100%;
      padding: 0;
      margin-bottom: 10px;
      max-height: 100%;

      img {
        width: 100%;
        display: none;
      }

    }

    .commentsAndMore,
    .dateAuthor,
    .teaser { display: none; }

    &:hover {

      li h4 a {
        color: $accent-color;
        text-decoration: none;
      }

    }

  }

}

// Custom Connect Styles //
.user_mode, #home.user_mode {
  .connect {

      > .pageElement {
        background: $link-color;
        border-top: solid 10px $accent-color;
      }

    .sn-social-media-list {
      background: transparent;
      border-bottom: solid 1px $light;
      padding: 0 10px 20px;

      .sn-social-media-icon {
        border-radius: 0;
        margin: 0 2px;
      }
    }

    .textBlockElement h3 {
      color: white;
      text-transform: uppercase;
      font-size: 32px;
      font-weight: 600;
      padding: 15px 25px 0 25px;
    }

    .text {
      padding-top: 0;

      p {
        color: $light;
        font-size: 14px;
        line-height: 26px;
        padding-right: 10px;

        a {
          color: $accent-color;
          font-weight: 300;

          &:after {
            content: '\f178';
            display: inline-block;
            margin-left: 10px;
            font-family: FontAwesome;
          }

        }

      }

    }

  }

}

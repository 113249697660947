// /* Accordion */
// .accordion{
// 	display: none;
// 	margin: auto;
//
//   .column-1 .pageEl { margin-bottom: 20px; }
//
//   .pageEl .textBlockElement .text > ul { padding-left: 20px; }
//
// 	.text {
// 		padding: 0 10px;
// 	}
// 	.pageEl .pageElement{
// 		// margin: 20px;
// 	}
// 	a.btntoggle {
// 		background-color: $lightest;
// 		border: 1px solid $light;
//     border-bottom: none;
// 		color:$link-color;
// 		display: block;
// 		font: 700 2em/1em $fontOne;
// 		padding: 15px;
// 		position: relative;
// 		transition: all 300ms ease 0s;
// 		// margin: 0 30px 0 0;
// 		-webkit-font-smoothing: antialiased !important;
//
//     &:last-of-type { border-bottom: 1px solid $light; }
//
// 	}
// 	a.btntoggle:hover{
// 		background-color: white;
//     text-decoration: none;
// 	}
// 	a.btntoggle.minus{
// 		background-color: $lightest;
// 		border-bottom: 4px solid $accent-color;
// 		-webkit-transition: all 500ms;
// 		transition: all 300ms;
// 		text-shadow: none;
// 		color: $link-color;
// 		// margin-bottom: 20px;
// 	}
// 	a.btntoggle span{
// 	  width: 90%;
// 	  display: block;
// 	}
// 	a.btntoggle:after{
// 	    background: none;
// 	    color: $accent-color;
// 	    font-size: 25px;
// 	    margin-left: 3px;
// 	    margin-right: 20px;
// 	    padding: 0px 6px 2px;
// 	    position: absolute;
// 	    right: 0;
// 	    text-align: center;
// 	    top: calc(50% - 12px);
// 	    width: auto;
// 	    // border: solid 2px $accent-color;
// 	    border-radius: 100px;
//       line-height: 25px;
// 	}
// 	a.btntoggle.minus:after{
// 		content:'-';
// 		color: $accent-color;
// 		font-weight: 100;
// 		text-shadow: none;
// 		padding: 0px 9px 2px;
// 	}
// 	// a.btntoggle.minus:before {
// 	// 	content: "";
// 	// 	display: block;
// 	// 	position: absolute;
// 	// 	width: 0;
// 	// 	height: 0;
// 	// 	border-left: 20px solid transparent;
// 	// 	border-right: 20px solid transparent;
// 	// 	border-top: 20px solid $light;
// 	// 	bottom: -20px;
// 	// 	right: 15px;
// 	// }
// 	a.btntoggle.plus:after{
// 		content:'+';
// 		color: $accent-color;
// 		font-weight: 100;
// 		text-shadow: none;
// 		background: none;
// 	}
// 	.btnlist p{
// 		padding: 20px;
// 		font-size: 16px;
// 		line-height: 1.5em;
// 	}
// 	a.btntoggle:last-of-type {
// 		margin-bottom: 30px;
//
//     &.minus { margin-bottom: 0; }
// 	}
// }
// .edit_mode .accordion{
// 	display: block;
// }

.user_mode {

  .accordion {
    padding-left: 0;
    padding-bottom: 0;

		&.layout-100,
		&.layout-50-50,
		&.layout-66-33,
		&.layout-33-66,
		&.layout-33-33-33,
		&.layout-50-25-25,
		&.layout-25-50-25,
		&.layout-25-25-50 {
			.column-1 {
				padding-right: 30px;
        @media only screen and (max-width: 768px){ padding-right: 0; }
			}
		}

    .column { padding: 0; }

    // a { color: $dark; }

    .event-link {
      display: block;
      border-bottom: solid 1px $light;
      position: relative;

      // &:after {
      //   content: '\f105';
      //   position: absolute;
      //   font-family: FontAwesome;
      //   right: 20px;
      //   top: calc(50% - 12px);
      //   color: $gray;
      //   transition: all $transition-standard;
      // }

      // &:hover:after { color: $accent-color; }

    }

    .accordion-title {
      cursor: pointer;
      border: solid 1px $lightest;
      border-bottom: none;
      padding: 17px 15px 14px;

      .pageElement {
        margin-bottom: 0;

        h3 {
          margin-bottom: 0;
          font-weight: 600;
          padding: 0;

          &:before {
            content: '+';
            display: inline-block;
            color: $accent-color;
            margin-right: 10px;
            transition: all $transition-standard;
          }

        }

      }

    }

    .accordion-content {
      display: none;
			border: solid 1px $lightest;
      border-top: solid 3px $link-color;
      padding-top: 15px;

      .documentElement h3 { margin: 0; }

      .excel:before, .imageJpg:before, .imagePng:before, .pdf:before { padding: 3px 0; }

      .pageEl {
        padding: 10px 15px;
        transition: all $transition-standard;


				.textBlockElement .text > ul { padding-left: 20px; }

        // &:hover { background: white; }

        &:last-of-type { border-bottom: none; }

        .pageElement { margin: 0; }

        .leftTextImage,
        .rightTextImage,
        .smallTextImage,
        .originalTextImage {
          min-height: 50px !important;
          height: 50px !important;
          max-height: 50px !important;
          width: 50px !important;
          margin: 0 15px 0 0;
        }

        p {
          font-size: 14px !important;
          color: $dark !important;

          strong {
            font-size: 16px !important;
            font-weight: 600 !important;
            color: $dark !important;
          }
        }

      }

    }

    &:last-of-type {

      .accordion-title { border-bottom: solid 1px $lightest; }

    }

  }


    .double-column .accordion-content {
      .pageEl {
        @media only screen and (min-width: 640px){
          width: 50%;
          display: inline-block;
        }
      }
    }

    .triple-column .accordion-content {
      .pageEl {
        @media only screen and (min-width: 768px){
          width: 33.3333333%;
          display: inline-block;
        }
        @media only screen and (max-width: 768px){
          width: 50%;
          display: inline-block;
        }
        @media only screen and (max-width: 640px){ width: 100%; }
      }
    }



}

.user_mode .accordion .accordion-open {

  .accordion-title .pageElement h3 {

      &:before {
        content: '-';
      }

  }

}

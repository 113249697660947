.right-panel-layout {

  #panelTwo {
    // width: 70%;
    // float: left;
    // padding-right: 0;
    @media only screen and (max-width: 768px){
      width: 100%;
      padding-right: 15px;
    }
  }

  #panelThree {
    // width: calc(29% - 30px);
    // float: left;
    // padding: 15px;
    // border: solid 1px $light;
    @media only screen and (max-width: 768px){
      width: 100%;
      padding-left: 15px;
      position: fixed;
      background: white;
      bottom: 0;
      left: 0;
      z-index: 999;
      padding: 0;

      .right-panel {
        display: none;
        padding: 15px;
      }

      #mobile-action {
        display: block;
        padding: 0 15px;
        position: relative;
        cursor: pointer;

        &:after {
          content: '\f055';
          display: inline-block;
          position: absolute;
          right: 15px;
          top: 5px;
          font-size: 24px;
          font-family: FontAwesome;
          color: $accent-color;
          transition: all $transition-standard;
        }

        h3 {
          padding: 10px 0;
        }

      }

      &.open-panel {
        position: absolute;
        top: 0;

        .right-panel {
          overflow: scroll !important;
          padding-top: 60px;
        }

        #mobile-action {
          background: $light;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1;

          &:after {
            content: '\f056';
          }

        }
      }

    }

    .layoutContainer .pageElement { margin-bottom: 0; }

    .pageEl:first-of-type {

      .textBlockElement {

        h3 {
          position: relative;
          text-transform: uppercase;
          padding-top: 0;

          &:after {
            content: '';
            position: absolute;
            height: 1px;
            width: calc(100% + 48px);
            left: -18px;
            bottom: 0;
            background: #aaaaaa;
          }

        }

      }

    }

    h3 {
      padding: 15px 0 0 0;
      text-transform: none;
      padding-bottom: 15px;
      margin-bottom: 0;
    }

    .documentElement {
      margin-left: 10px;
      padding: 3px 0;

      h3 {
        padding-left: 30px;
        font-size: 14px;

        a {
          font-weight: 300;
          color: $gray;
          width: 100%;
        }

      }
    }

    .column { padding-left: 0; }

    .section-divide {

      h3 {
        border-top: solid 1px #aaaaaa;
        padding-top: 10px;
      }

    }

  }

}

// Doc image replacement
.imageJpg,
.pdf,
.imagePng,
.excel {
  background-image: none;
  position: relative;
  transition: all $transition-standard;

  &:hover {
    a { color: $accent-color !important; }

    &:before { color: $accent-color; }

  }

  &:before {
    content: '\f0f6';
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 40px;
    top: 0;
    left: 0;
    text-align: left;
    color: #c7c7c7;
    font-family: FontAwesome;
    font-size: 20px;
    padding: 10px 0px;
    transition: all $transition-standard;
  }


}

#mobile-action { display: none; }

// Custom Footer Styles //
#footer-logo {
  text-align: center;
  img {
    max-height: 115px;
  }

}

#footer-links {
  text-align: center;
  border-bottom: solid 1px $link-color;
  padding: 10px 0 20px;

  a {
    color: white;
    padding: 0 2%;
  }

}

#footer-newsletter,
#footer-connect {
  float: left;
  width: 50%;
}

#footer-newsletter {
  line-height: 50px;
  padding: 15px 0;

  a {
    color: $accent-color;
    font-size: 16px;

    &:after {
      content: '\f178';
      font-family: FontAwesome;
      margin-left: 10px;
      display: inline-block;
    }

  }

}

#footer-connect {
  text-align: right;
  padding: 15px 0;

  .sn-social-media-list .sn-social-media-icon { border-radius: 0; }

}

#siteFooter > ul li a#loginLink { display: none; }
